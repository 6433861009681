/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */
.user-status{
	text-transform: capitalize;
}
.flatpickr-input {
	width : 150px
}
.react-dataTable > div:first-child {
	min-height: 590px;
}
// Change the datatable padding
.padding-remove-dataTable {
	.rdt_TableCol{
		padding: 0.4rem 0.5rem !important;
		min-width: 30px !important;
	}
	.rdt_TableCell{
		padding: 0.4rem 0.5rem !important;
		min-width: 30px !important;
		.form-check {
			padding-left: 1px
		}
	}
}

// Change the active dropdown menu background and color for sidebar
.main-menu.menu-light .navigation > li.sidebar-group-active > a {
	background: transparent !important;
	color: #ffffff !important;
}

.employee-personal-card {
	.employee-info {
		min-width: 250px;

		div {
			margin-top: 5px;
		}
	}
}

.card-header-with-border-bottom {
	border-bottom: 2px solid rgba(34, 41, 47, 0.125) !important;
	padding: 1rem 1.5rem !important;
}
.permission-table-field-item {
	margin-left: 5px;
	margin-bottom: 5px;
}
.loader-overlay {
	position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: #00000011;
}
.permission-super-permissions-container {
	height: 300px;
	overflow-x: hidden;
	overflow-y: auto;
}
.ant-picker-input {
	height: 30px;
}

span.font-weight-normal {
	font-weight: 400;
}
select{
	&.select-borderless {
		border: none
	}
	&.text-primary {
		color: var(--bs-primary) !important;
	}
	&.text-warning {
		color: var(--bs-warning) !important;
	}
	&.text-success {
		color: var(--bs-success) !important;
	}
	&.text-secondary {
		color: var(--bs-secondary) !important;
	}
	&.text-dark {
		color: var(--bs-dark) !important
	}
	&.text-info {
		color : var(--bs-info) !important;
	}
	&.text-danger {
		color: var(--bs-danger) !important;
	}
}
.review-container{
	background-color: #f1eeee;
	margin-left: -20px;
	margin-right: -20px;
	padding-top: 10px;
	padding-bottom: 10px;
}

.job-detail-fixed {
	position: fixed;
	top: 90px;
    bottom:0;
    position:fixed;
    overflow-y:auto;
    overflow-x:hidden;
}

.job-selected {
	background-color: #f7f3f3;
	box-shadow: inset 0 -3em 3em rgb(120 160 247 / 10%), 0 0 0 1px rgb(220 227 249), 0.3em 0.3em 1em rgb(162 144 241 / 30%);
}
.job-notes {
	background-color: transparent;
	overflow-x: auto;
	white-space: pre-wrap;
	white-space: -moz-pre-wrap;
	white-space: -pre-wrap;
	white-space: -o-pre-wrap;
	word-wrap: break-word;
}